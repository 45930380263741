<!--
@component
Holding page route for verifying ADC checks, and is only reached if all pax checked in but some missing ADC checks.
  -->
<script>
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { onMount } from 'svelte';
  import { push } from 'svelte-spa-router';
  import countryCodes from 'intl-tel-input/build/js/data';
  import flightdeckConsts from '../../js/services/flightdeck/const';
  import { baseStationCode } from '../../js/stores/config';
  import {
    booking,
    headPassenger,
    infants,
    setErrorModal,
    CustomErrorModal,    
    transactionId,
    passengersTimaticActions  
  } from '../../js/stores';
  import { convertIataToIso2, getKeyByValue } from '../../js/utils';
  import { ErrorModals } from '../../js/const/errorModals';
  import iso2Iso3 from '../../js/data/miscellaneous/iso2iso3.json';
  import { ApplicationStep, appReport } from '../../js/appReport';
  import logger from '../../js/logger';
  import switchboardClient from '../../js/services/switchboard';
  import flightdeck from '../../js/services/flightdeck';
  import { END_TRANSACTION_REASON } from '../../js/const';
  import hasRecordInUseError from '../../js/recordInUseError';
  import endTransaction from '../../js/endTransaction';
  import Footer from '../components/Footer/index.svelte';
  import Header from '../components/Header/index.svelte';
  import Loading from '../components/Loading.svelte';
  
  let serviceRetryCount = 0;
  let timaticFlightDeckAlertCount = 0;
  let timaticFailErrorModal;
  let retryAttempts = 0;
  const MAX_TIMATIC_OVERRIDES_ALLOWED = 1;

  let isVisaEntryRequired = false;
  let passengersWithTimaticActions = [];
  let showSpinner = null;
  let showUpdating = null;
  let showWaiting = null;
  let uniqueCombinedCountries = [];

  /**
   * editCPR and buildEditCPR are from EmergencyContact, however, an additional flag is passed. 
   * These could be refactored into a service layer the code is similar for now.
   */
  function editCPR(input) {
    switchboardClient.editCPR(input).then((response) => {
      logger.info('edit cpr response :', response);

      if (response) {
        const errorMessages = response.data?.editCpr?.errorMessages;

        if (errorMessages && errorMessages?.length > 0) {
          logger.error('Error(s) in timatic checks (Edit CPR) amadeus response');

          // check if record in use error is received
          if (hasRecordInUseError(errorMessages)) {
            serviceRetryCount++;
            if (serviceRetryCount < 3) {
              logger.info('Record in use error received from editCPR emergency contacts. waiting three seconds to recall the service')
              setTimeout(() => {
                editCPR(input)
                return;
              }, 3000);
              return;
            } else {
              logger.info('Record in use error received from editCPR emergency contacts. Maximum retry attempts reached. Ending transaction.')
            }
          }

          errorMessages.forEach((error) => {
            logger.error(error?.message);
          });

          flightdeck.rerunADCChecksFailed();
          setErrorModal(ErrorModals.ASSISTANCE_REQUIRED_ADC_CHECKS_FAILED);
          return; // if we receive EC category messages from response, we want to log the errors and exit
        }

        const { passengers } = get(booking);
        const timaticChecks = response.data?.editCpr?.passengerTimaticChecks;
        let timaticOverrideRequired = false;

        if (timaticChecks && timaticChecks.length > 0) {
          timaticChecks.forEach((check) => {
            if (check) {
              let currentPassenger = passengers.filter((x) => x.passengerID === check.customerPrimeId);

              if (currentPassenger === null || currentPassenger?.length === 0) { // it may be an infant
                currentPassenger = [booking.getInfantByID(check.customerPrimeId)];
              }

              if (!currentPassenger) { return; }

              let passengerName = currentPassenger[0].firstName + ' ' + currentPassenger[0].lastName;

              if (check.timaticInfoStatus && check.timaticInfoStatus?.length > 0) {
                check.timaticInfoStatus.forEach((status) => {
                  logger.info(`Passenger ${passengerName} has timatic status of ${status} received.`);

                  // just need to set it once, since if other passengers have Conditional or any other status, due to even 1 NOT_OK_TO_BOARD, we would need visa entry
                  if (status === 'NOT_OK_TO_BOARD') {
                    isVisaEntryRequired = true;
                  }
                  
                  passengersWithTimaticActions.push(
                    { 
                      passengerName: passengerName,
                      passengerId: check.customerPrimeId,
                      status: status,
                      flightDeckActioned: false,
                      isVisaDetailsEntered: false, // on required for status == NOT_OK_TO_BOARD 
                      purposeOfVisit: null,
                      destinationCountry: null,
                      visaNumber: null,
                      visaIssueCountry: null,
                      visaExpiryDate: null,
                      documentType: null
                    }
                  );

                  logger.info(`passengersWithTimaticActions list is: ${JSON.stringify(passengersWithTimaticActions)}`);

                  let message = `Passenger: ${passengerName}, Nationality: ${currentPassenger[0].passportIssuingCountry}`;
                  if (
                    check.flightDeckMessage &&
                    check.flightDeckMessage.length > 0
                  ) {
                    let flightDeckMessage = check.flightDeckMessage[0];
                    try {
                      flightDeckMessage = flightDeckMessage.replace(
                        /<\/?[^>]+(>|$)/g,
                        '',
                      );
                    } catch (error) {
                      flightDeckMessage =
                        'ADC/Timatic status of CON or NOT OK TO BOARD Found';
                    }
                    message = `${message} - ${status} - ${flightDeckMessage}`;
                  } else {
                    message = `${message} - ${status} - ADC/Timatic status of CON or NOT OK TO BOARD Found`;
                  }

                  flightdeck.timaticIndividual({
                    passenger: `Passenger ${passengerName} timatic/ADC checks have ${status}. PassengerId:${check.customerPrimeId}`,
                    message: message,
                    passengerId: check.customerPrimeId
                  });

                  let eventDetails = { error: message }
                  appReport.updateStepInProgress(ApplicationStep.PASSENGER_ADC_CHECKS, eventDetails);

                  timaticFlightDeckAlertCount =
                    timaticFlightDeckAlertCount + 1;
                });

                timaticOverrideRequired = true;

                // after logging all messages on console, showing flight deck alert
                if (retryAttempts < MAX_TIMATIC_OVERRIDES_ALLOWED) {
                  timaticFailErrorModal.open(
                    END_TRANSACTION_REASON.TIMATIC_CHECKS_FAILED,
                  );
                } else {
                  endTransaction(END_TRANSACTION_REASON.TIMATIC_CHECKS_FAILED);
                }
              }
            }
          });
        }
        // check if any of the passengers required a timatic agent override, 
        // if not, allow to navigate forward
        logger.info('timatic overrides required = ', timaticOverrideRequired);
        if (timaticOverrideRequired === false) {
          appReport.updateStepSuccess(ApplicationStep.PASSENGER_ADC_CHECKS);
          //push('/number-of-bags');
          push('/checking-in-passengers');
        }
      } else {
        logger.error('No response received from the server. ');
        setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);
      }
    }),
      (error) => {
        logger.error('Amadeus Edit CPR service returned an error. ', error);
        setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);
      };
  }

  /**
   * Create the BuildEditCPRInput object for SwitchBoard. 
   * Difference from here to the one in Emergency contact is that only do those that have ADC check not done
   *
   * @param {object} editCprInput - Switchboard EditCprInput object.
   * @returns {Array} passengerDetailsList - Array of passengerDetails.
   */
  function buildEditCPRInput(isAgentOverride) {
    let editPassengerDetailsList = [];

    const { passengers } = get(booking);
    const allPassengers = (passengers || []).concat(get(infants));

    allPassengers.forEach((passenger) => {     
    // Edit cpr does not want infants
    // passengers.forEach((passenger) => {     
      passenger.segments.forEach((segment) => {
        let passengerDetails = {
          isAgentOverride: isAgentOverride,
          firstName: passenger.firstName,
          gender: passenger.gender,
          surname: passenger.lastName,
          type: passenger.amadeusPassengerType, // passenger.type,
          dateOfBirth: passenger.dateOfBirth,
          customerPrimeId: passenger.passengerID,
          marketingCarrier: segment.marketingAirlineCode,
          flightNumber: segment.flightNumber, //passenger.flightNumber,
          departureDate: segment.departureDateTime, //passenger.departureDate,
          boardPoint: segment.departureCode, //passenger.boardPoint,
          productPrimeId: segment.passengerDID, // passenger.productPrimeId,
          travelerDocumentId: passenger.passportNumber,
          travelerDocumentExpiryDate: passenger.passportExpiry,
          code: passenger.passportIssuingCountry,
          nationalityCode: passenger.passportIssuingCountry
        };

        // Only need to edit pax that do not have ADC check complete
        if(!passenger.IsADCCheckComplete)
        {
            editPassengerDetailsList.push(passengerDetails);
        }
      });
    });

    // Do not update the emergency contact when in this flow, as we do not want to override anything
    // Given in check-in, just do the ADC check
    return {
      editPassengerDetailsList,
      onlyPerformRegulatoryChecks: true, // Implication of this is that Emergency contact / any future fields that are "optional" are not updated
      onlyUpdatePassengerEmail: false,
      onlyUpdateVisaDetails: false,
      baseStation: baseStationCode,
      transactionId: get(transactionId)
    };
  }

  function timaticOverrideUnsuccessfulChecks() {
    if (retryAttempts < MAX_TIMATIC_OVERRIDES_ALLOWED) {
      updatePassengerDetails(true);
      retryAttempts = retryAttempts + 1;
    }
  }

  timaticFailErrorModal = new CustomErrorModal(
    booking.isSinglePassengerTransaction()
      ? ErrorModals.TIMATIC_UNSUCCESSFUL_SINGLE_PASSENGER
      : ErrorModals.TIMATIC_UNSUCCESSFUL_MULTIPLE_PASSENGERS,
  );

  timaticFailErrorModal.setOverrideHandler(timaticOverrideUnsuccessfulChecks);

  function updatePassengerDetails(isAgentOverride) {
    const editCPRInput = buildEditCPRInput(isAgentOverride);
    editCPR(editCPRInput);
  }

  function handleFlightDeckOverride(flightdeckAction) {

    let data = JSON.parse(flightdeckAction?.transactionData?.data);
    logger.info(`flight deck action data is: ${JSON.stringify(data)}`);

    if (data) {
      let res = data.TimaticData?.Responses[0];
      if (res) {
        let indexOfPaxId = res.indexOf("PassengerId");
        if (indexOfPaxId > -1) {
          let passengerId = res.substring(indexOfPaxId).split(':')[1];
          logger.info(`updating the passengersWithTimaticActions with this timatic agent action`);
          passengersWithTimaticActions.forEach(p => {
            if (p.passengerId === passengerId) {
              logger.info('passenger id match found');
              p.flightDeckActioned = true;
            }
          });
        }
      }
    }

    logger.info(`updated passengersWithTimaticActions is : ${JSON.stringify(passengersWithTimaticActions)}`);

    timaticFlightDeckAlertCount = timaticFlightDeckAlertCount - 1;
    if (timaticFlightDeckAlertCount > 0) {
      return false;
    } else {
     if (!isVisaEntryRequired) {
        logger.info('No Visa Entry Required. All passengers actioned. Navigating to visa verification screen.');
        timaticOverrideUnsuccessfulChecks();
        return true;
      } else {
        logger.info('Visa Entry Required. All passengers actioned. Navigating to visa verification screen.')
        passengersTimaticActions.set(passengersWithTimaticActions);
        push('/visa-verification');
        return true;
      }
    }
  }

  /**
   * Update countries list with important countries placed at the top.
   *
   * @param {object[]} - An array of countries.
   * */
  function updateCountryCodes() {
    let combinedCountries = [];

    const baseStation = baseStationCode;
    const destination = booking.getArrivalCode();
    const nationality = booking.getPassengerNationality(get(headPassenger));

    const baseStationIso2 = (
      convertIataToIso2(baseStation) || ''
    ).toLowerCase();
    const destinationIso2 = (
      convertIataToIso2(destination) || ''
    ).toLowerCase();
    const nationalityIso2 = (
      getKeyByValue(iso2Iso3, nationality) || ''
    ).toLowerCase();

    const baseStationHighlight = countryCodes.find(
      (item) => item.iso2 === baseStationIso2,
    );
    const destinationHighlight = countryCodes.find(
      (item) => item.iso2 === destinationIso2,
    );
    const nationalityHighlight = countryCodes.find(
      (item) => item.iso2 === nationalityIso2,
    );

    combinedCountries = [
      ...[
        baseStationHighlight,
        destinationHighlight,
        nationalityHighlight,
      ].filter(Boolean),
      ...countryCodes,
    ];

    uniqueCombinedCountries = combinedCountries.filter((item, index) => {
      return combinedCountries.indexOf(item) === index;
    });
  }

  onMount(() => {
    logger.info("Entering Verifying ADC Checks... only for passengers who are checked in but ADC is not clear")
    
    showSpinner = false;
    showUpdating = false;
    showWaiting = false;

    timaticFailErrorModal.setFlightdeckHandler(
      flightdeckConsts.TransactionStatuses.Overridden,
      handleFlightDeckOverride,
    );

    timaticFailErrorModal.setFlightdeckHandler(
      flightdeckConsts.TransactionStatuses.VisaVerified,
      handleFlightDeckOverride,
    );

    timaticFailErrorModal.setFlightdeckHandler(
      flightdeckConsts.TransactionStatuses.Cancelled,
      handleFlightDeckCancelled,
    );

    updateCountryCodes();

    // Do ADC Check
    updatePassengerDetails(false);
  });

  function handleFlightDeckCancelled() {
    endTransaction(END_TRANSACTION_REASON.TIMATIC_CHECKS_FAILED);
    return true;
  }
</script>

<Header />

<Loading heading={$_('app.pleaseWait')} />

<Footer />
